import React from 'react'
import loaderFile   from '../../Assets/Images/loader.gif';
import subloaderFile   from '../../Assets/Images/sub-loader.gif';

function Loader({width='125px',type='main'}) {
  return (
    <div>
      <center>
        {type==='main' ?(
          <img className="loader" src={type==='main' ? loaderFile : subloaderFile} style={{ width: width }} alt="Loading..." />
        ) :(
          <div className='calender-loader'>
          <img className="loader" src={type==='main' ? loaderFile : subloaderFile} style={{ width: width }} alt="Loading..." />
        </div>
        )
      }
     </center>
    </div>
  )
}

export default Loader