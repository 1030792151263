import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import Loader from "../loader/Loader";

function RadioBtnField({ name, label, rules, options, className, optionsClassName,isLoading }) {
  
  const { control,setValue, formState: { errors },} = useFormContext();
  
  if(isLoading) return <Loader />;
  return (
    <div className={className}>
      <label htmlFor={name} className="form-label mb-3">
        {label}
      </label>

      
      <Controller
        name={name}
        control={control}
        defaultValue=''
        rules={rules}
        render={({ field }) => (
          <div className="row">
            {options?.map((option, index) => (
              <div key={index} className={`${optionsClassName || 'col-md-4'} mb-3`}>
              <div className="form-check form-checked">
              <input
                  name={name}
                  type="radio"
                  value={option.id}
                  className="form-check-input"
                  id={name+`-radio-`+option.id}
                  onClick={()=>setValue(name,option.id)}
                  checked={field.value===option.id}
                />
                {option.name && <label className="form-check-label ms-1 account-access" htmlFor={name+`-radio-`+option.id} onClick={()=>setValue(name,option.id)}>{option.name}</label>}
                {option.text && <label className="form-check-label ms-1 account-access" htmlFor={name+`-radio-`+option.id} onClick={()=>setValue(name,option.id)} dangerouslySetInnerHTML={{ __html: option.text }}></label>}
              </div>
              </div>
            ))}
          </div>
        )}
      />

      {errors[name] && <span className="error">{errors[name].message}</span>}
    </div>
  );
}

export default RadioBtnField;
