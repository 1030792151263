import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { extractNumbers, getselectedIds, MakeAxiosRequest } from "../../utils/handler";
import { phoneRegex } from "../../Constants";
import { useDispatch } from "react-redux";
import ModalBox from "../../Components/modals/ModalBox";
import FormFields from "../../Components/inputs/FormFields";
import { SwalError, SwalSuccess } from "../../Components/SwalFns";
import { updateProfile } from "../../features/authSlice";

function CreateRelationship({ showModal, closeModal, editKey,listingFn,profileEdit,getResponse,updateBillingInfo }) {
  const methods = useForm();
  const dispatch = useDispatch();
  const { handleSubmit, reset, setValue, watch, trigger, getValues } = methods;
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [prefillDetails, setPrefillDetails] = useState([]);
  const [optionsList, setOptionsList] = useState({
    relations: [],
    proxyType: [],
    additionalNeed: [],
    states: [],
  });
  const [imageData, setImageData] = useState();
  const [hasImage, sethasImage] = useState();

  const submitUrl =  profileEdit ? '/updateprofile' : (editKey ? '/updateproxy' : '/saveproxy');

  const fetchData = async () => {
    try {
      const options = await MakeAxiosRequest("post", "/getadditionalneeds");
      const statesList = await MakeAxiosRequest("post", "/getstates");
      const genderList = await MakeAxiosRequest("post", "/getgenders");
      if(!profileEdit && editKey){
        const edit = await MakeAxiosRequest("post", "/editproxy",{user_proxy_key: editKey});
        setPrefillDetails(edit.data.proxyData);
        // console.log(edit.data.proxyData)
      }else if(profileEdit){
        const profile = await MakeAxiosRequest("post", "/myprofile");
        setPrefillDetails({...profile.data.userdetails,additionalneeds:profile.data.additionalneeds})
      }      

      const gender = genderList.data.genders?.map((item) => ({
        id: item.id,
        name: item.gender,
      }));
      const states = statesList.data.state?.map((item) => ({
        // Transform each item as needed
        id: item.state_prefix,
        name: item.state_prefix,
      }));
      const relations = options.data.relations?.map((item) => ({
        id: item.id,
        name: item.relation,
      }));
      const proxyType = options.data.proxyType?.map((item) => ({
        id: item.id,
        name: item.proxy_type,
      }));
      const additionalNeed = options.data.additionalNeed?.map((item) => ({
        id: item.id,
        name: item.additional_need,
      }));
      const walletPermission = options.data.wallet_permission;
      setOptionsList({ relations, proxyType, additionalNeed, states, gender, walletPermission });
      setIsLoading(false)
      // if(res.status=='0'){
      //   SwalError({msg:res.message})
      // }
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    fetchData();
    setSubmitDisabled(false);
    return () => {
      setSubmitDisabled(false);
      reset();
    };
  }, [showModal]);

  useEffect(() => {
    // console.log(prefillDetails);

    setSubmitDisabled(false);
    reset({
      first_name: prefillDetails.first_name || "",
      last_name: prefillDetails.last_name || "",
      nick_name: prefillDetails.nick_name || "",
      email: prefillDetails.email || "",
      phone_number: extractNumbers(prefillDetails.phone_number) || "",
      country_code: prefillDetails.country_code || "",
      gender_id: prefillDetails.gender_id || "",
      proxy_relation_id: prefillDetails.proxy_relation_id || "",
      proxy_relation_other: prefillDetails.proxy_relation_other || "",
      city: prefillDetails.city || "",
      // state: prefillDetails.state_id ||prefillDetails.state||"",
      state: prefillDetails.state||"",
      proxy_type_id: prefillDetails.proxy_type_id || "",
      postal_code: prefillDetails.postal_code || "",
      zip: prefillDetails.zip || "",
      address: prefillDetails.address ||prefillDetails.addrerss || "",
      additionalneeds:getselectedIds(prefillDetails.additionalneeds,'isselected',1) || [],
      wallet_permission_enabled: prefillDetails.wallet_permission_enabled || '0',
      others: prefillDetails.otherinfo || "",
    });
    sethasImage(prefillDetails.isdefImage=='0' ? 1 : 0)
  }, [prefillDetails]);


  const createUser = async (data) => {
    setSubmitDisabled(true);
    if(updateBillingInfo){
      updateBillingInfo(data);
    }else{
      try {
        // console.log(data);
        // console.log(submitUrl);
        data.phone_number = extractNumbers(data.phone_number);
        data.wallet_permission_enabled = data.wallet_permission_enabled==1 ? '1' : '0';
        const response = await MakeAxiosRequest("post", submitUrl, { ...data, hasImage, ...imageData, user_proxy_key: editKey });
        // console.log(response);
        if (response.status == 1) {
          getResponse && getResponse(response.data,(profileEdit ? 'profile' : (editKey ? 'update' : 'create')))
          reset();
          closeModal();
          listingFn && listingFn();
          if(response.data?.msg_key =='subText'){
            SwalSuccess({subText: response.data?.message, showbtn:true})
          }else{
            SwalSuccess({ msg: response.message});
          }
          profileEdit && dispatch(updateProfile());
        } else if (response.status == 0) {
          SwalError({ msg: response.message });
        } else {
          // setError('phone_number', { type: 'custom', message: response.message });
          console.log(response.message);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
    setSubmitDisabled(false);
  };
  
  const handleAddressSelected = (addressComponents) => {
    // console.log(addressComponents);
    // Update form fields individually using setValue
    for (const [key, value] of Object.entries(addressComponents)) {
      setValue(key, value);
      trigger(key);
    }
  };

  const fields = [
    {
      name: "hasImage",
      type: "profileImage",
      className: "text-center mb-4",
      // rules: {
      //   validate: {
      //     validateImg: async () => {
      //       // return (hasImage == 0 || hasImage == undefined) && "Please upload image";
      //       var validationmessage = (hasImage == 0 || hasImage == undefined) ? (`Please upload image`) : true;
      //       return  validationmessage;
      //     },
      //   },
      // },
      imagePath: prefillDetails?.image_path||prefillDetails?.profile_image,
      hasImage: hasImage,
      sethasImage: sethasImage,
      setImageData: setImageData,
      hideRemovebtn: !isSubmitDisabled,
      isShow: !getResponse,
    },
    {
      name: "wallet_permission_enabled",
      label: optionsList?.walletPermission?.permission_title,
      text: optionsList?.walletPermission?.permission_text,
      className: "col-12 mb-3",
      type: "agreeButton",
      isShow: (profileEdit || !!getResponse ) ? false : optionsList?.walletPermission?.is_show_permission,
    },
    {
      name: "proxy_relation_id",
      label: "This person is my :",
      className: "col-md-6 mb-3",
      rules: { required: "Please select relation" },
      type: "select",
      options: optionsList.relations,
      isShow: profileEdit ? false : true,
    },
    {
      name: "phone_number",
      countryCodeName: "country_code",
      label: "Phone Number",
      className: "col-md-6 mb-3",
      rules: {
        required: "Please enter phone number",
        pattern: {
          value: phoneRegex,
          message: "Please enter a valid phone number",
        },
        // validate: (value) => {
        //   console.log(value);
        //   console.log(phoneRegex.test(value));
        //   return phoneRegex.test(value) || "Please enter a valid phone number";
        // },
        ...(!editKey && !profileEdit && {
        validate: {
          existCheck: async (fieldValue) => {
            const response = await MakeAxiosRequest("post","/searchproxybyphone",{ phone_number: extractNumbers(fieldValue), country_code: getValues('country_code') });
            return response?.status == 1 || response?.message;
          },
        },
      }),
      },
      isdisabled: !!editKey || !!profileEdit,
      mask: "(999) 999-9999",
      type: "phonenumberfield",
      isShow: profileEdit ? false : true,
    },
    {
      name: "first_name",
      label: "First Name",
      className: "col-md-6 mb-3",
      rules: { required: "Please enter first name" },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "last_name",
      label: "Last Name",
      className: "col-md-6 mb-3",
      rules: { required: "Please enter last name" },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "email",
      label: "Email",
      className: "col-md-6 mb-3",
      rules: { required: "Please enter email" },
      type: "inputfield",
      isShow: profileEdit ? true : false,
    },
    {
      name: "nick_name",
      label: "Nickname",
      className: "col-md-6 mb-3",
      // rules: { required: "Please enter nick name" },
      type: "inputfield",
      isShow: profileEdit ? false : true,
    },
    {
      name: "phone_number",
      label: "Phone Number",
      className: "col-md-6 mb-3",
      rules: {
        required: "Please enter phone number",
        pattern: {
          value: phoneRegex,
          message: "Please enter a valid phone number",
        },
        ...(!editKey && !profileEdit && {
        validate: {
          existCheck: async (fieldValue) => {
            const response = await MakeAxiosRequest("post","/searchproxybyphone",{ phone_number: extractNumbers(fieldValue) });
            return response?.status == 1 || response?.message;
          },
        },
      }),
      },
      isdisabled: !!editKey || !!profileEdit,
      mask: "(999) 999-9999",
      type: "numberfield",
      isShow: profileEdit ? true : false,
    },
    {
      name: "gender_id",
      label: "Gender",
      className: "col-md-6 mb-3",
      rules: { required: "Please select gender" },
      type: "select",
      options: optionsList.gender,
      isShow: profileEdit ? true : false,
    },
    {
      name: "proxy_relation_other",
      label: "Other relation",
      className: "col-md-6 mb-3",
      rules: { required: "Please enter your relationship" },
      type: "inputfield",
      isShow: watch('proxy_relation_id')=='13',
    },
    {
      name: "address",
      label: "Address",
      className: "col-12 mb-3",
      rules: { required: "Please enter address" },
      // type: "textarea",
      type: "addressautocomplete",
      onAddressChange: handleAddressSelected,
      isShow: true,
    },
    {
      name: "city",
      label: "City",
      className: "col-md-6 mb-3",
      rules: { required: "Please enter city" },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "state",
      label: "State",
      className: "col-md-6 mb-3",
      rules: { required: "Please select state" },
      type: "select",
      options: optionsList.states,
      isShow: true,
    },
    {
      name: profileEdit ? "zip" :"postal_code",
      label: "Zip",
      className: "col-md-6 mb-3",
      rules: { 
        required: "Please enter zip",
        pattern: {
          value: /^\d{5}$/,
          message: "Please enter a valid zip",
        },
      },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "proxy_type_id",
      label: "Role",
      className: "col-md-6 mb-3",
      // rules: { required: "Please select your relationship to the individual" },
      type: "select",
      options: optionsList.proxyType,
      isShow: profileEdit ? false : true,
    },
    {
      name: "additionalneeds",
      label: "Is there any thing else we should know about this person?",
      className: "col-12 mb-3",
      // rules: { required: "Please select" },
      type: "checkbox",
      options: optionsList.additionalNeed,
      isShow: true,
    },
    {
      name: "others",
      label: "Provide Details",
      className: "col-12 mb-3",
      rules: { required: "Please provide details" },
      type: "textarea",
      isShow: watch('additionalneeds')?.includes(4),
    },
  ];
  
  return (
    <ModalBox
      isShow={showModal}
      handleClose={closeModal}
      modalSize="lg"
      hasData={!isLoading}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(createUser)}>
          <div className="text-center">
            <h3 className="modal-title">
            {
              getResponse 
                ? (profileEdit ? 'User Info' : 'Relationship Info') 
                : (profileEdit ? 'Edit Profile' : (editKey ? 'Edit Relationship' : 'Create Relationship'))
            }
            </h3>
          </div>
          <div className="form-group mt-4">
            <div className="row">
              <FormFields fields={fields} />
            </div>
          </div>
          <div className="mt-4">
            <button
              type="submit"
              className="btn btn-primary rounded-pill w-100 p-2"
            >
              {profileEdit ? 'Save Account': (editKey ? 'Update' : 'Submit')}
            </button>
          </div>
        </form>
      </FormProvider>
    </ModalBox>
  );
}

export default CreateRelationship;
