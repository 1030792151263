import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MakeAxiosRequest } from '../utils/handler';

const storedUser = localStorage.getItem('user');


// Async thunk for fetching the notification count
export const updateProfile = createAsyncThunk(
  'fetchprofile',
  async () => {
    const response = await MakeAxiosRequest("post", "/myprofile");
    return response.data.userdetails;
  }
);

export const fetchNotificationCount = createAsyncThunk(
  'notification/fetchCount',
  async () => {
    const response = await MakeAxiosRequest("post", "/notificationlist");
    return response.data.notificationcount;
  }
);

export const fetchWalletAmount = createAsyncThunk(
  'wallet/fetchAmt',
  async () => {
    const response = await MakeAxiosRequest("post", "/walletlistingnew");
    return response.data.banner_details.balance_amount;
  }
);

const initialState = {
  isAuthenticated: Boolean(storedUser),
  notificationCount: 0,
  walletAmount: '',
  membershipStatusChange: false,
  user: storedUser ? JSON.parse(storedUser) : null,

//   pendingcount:  {
//     issueCount:0,
//     revewsCount:0,
//     offersCount:0,
//     unReadCount:0
// }
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {

    login: (state, action) => {
      localStorage.setItem('accesstoken', action.payload.accesstoken);
      localStorage.setItem('user', JSON.stringify(action.payload));
      state.isAuthenticated = true;
      state.user = action.payload;
      state.proxyKey = '';
      state.proxyType = '';
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.proxyKey = '';
      state.proxyType = '';
      state.notificationCount= 0;
      state.walletAmount= '';
      state.membershipStatusChange= false;
      state.user = null;
      localStorage.removeItem('accesstoken');
      localStorage.removeItem('user');
      console.log("logout");
    },
    changeMembership: (state) => {    
        state.membershipStatusChange = !state.membershipStatusChange;
    },
    switchProfile: (state,action) => {
      state.proxyKey = action.payload.key;
      state.proxyType = action.payload.type;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(updateProfile.fulfilled, (state, action) => {
      const first_name = action.payload.first_name;
      const last_name = action.payload.last_name;
      const profileimg = action.payload.profile_image;

      const localData = localStorage.getItem('user');
      let parsedData = {};
      if (localData) {
        parsedData = JSON.parse(localData);
      }
      parsedData.first_name = first_name;
      parsedData.last_name = last_name;
      parsedData.profileimg = profileimg; // Update the image
      const updatedData = JSON.stringify(parsedData);
      localStorage.setItem('user', updatedData);

      state.user.first_name = first_name;
      state.user.last_name = last_name;
      state.user.profileimg = profileimg;
    });

    builder.addCase(fetchNotificationCount.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.notificationCount = action.payload;
    });

    builder.addCase(fetchWalletAmount.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.walletAmount = action.payload;
    });
    
  },
});

export const { login, logout, update, changeMembership, switchProfile } = authSlice.actions;
export default authSlice.reducer;