import React, { useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import ModalBox from "../modals/ModalBox";
import { isEmptyObject } from "../../utils/handler";
import NoData from "../../Pages/Relationship/DetailsContent/NoData";

function PopupSelect({ name, label, rules, options, className }) {
  const {
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // Add state for search term
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedOption, setSelectedOption] = useState({});

  const handleConfirm = () => {
    setSelectedOption(options.find((opt) => opt.id === selectedValue));
    setValue(name, selectedValue)
    setShowModal(false);
    setSearchTerm('');
    trigger(name);
  };

  return (
    <div className={className}>
      {isEmptyObject(selectedOption) && <label htmlFor={name} className="form-label mb-3">
        {label}
      </label>}

      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={rules}
        render={({ field }) => (
          <div className="row">
            <div onClick={() => setShowModal(true)}>
              {!isEmptyObject(selectedOption) ? (
                <div className="d-flex align-items-center justify-content-center flex-wrap text-center ">
                  <div>
                    <div className="profile-img">
                      <img
                        src={selectedOption.img}
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                    <div className="mt-2 mt-md-0 ms-lg-1 text-lg-start rltnshp-txt">
                      <p className="mb-1 text-center" style={{fontSize: '20px', fontWeight: '700', margin: '0px !important'}}>{selectedOption.name}</p>
                      {selectedOption.subtext && (
                        <small className="d-block text-center">
                          {selectedOption.subtext}
                        </small>
                      )}
                    </div>
                  </div>

                </div>
              ) : (
                <input className="form-control" readOnly/>
              )}
            </div>
            <ModalBox
              isShow={showModal}
              handleClose={() => setShowModal(false)}
              modalSize="md"
              hasData={true}
              // hideClose={true}
            >
              <div className="text-center position-relative">
                <h3 className="modal-title mb-3 mt-0">{label}</h3>
              </div>
              <div className="row">
                <div className="col-12 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                {options?.length > 0 ? (
                  (() => {
                    const filteredOptions = options.filter((option) =>
                      option.name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    );

                    return filteredOptions.length > 0 ? (
                      filteredOptions.map((option, index) => (
                        <div key={index} className="col-12 mb-3">
                          <div className="row">
                            <div className="col-11">
                              <label
                                className="form-check-label ms-1"
                                htmlFor={name + `-radio-` + option.id}
                                onClick={() => setSelectedValue(option.id)}
                              >
                                <div className="d-flex align-items-center justify-content-center justify-content-md-start flex-wrap text-center ">
                                  <div className="profile-img">
                                    <img
                                      src={option.img}
                                      style={{ width: "35px", height: "35px" }}
                                    />
                                  </div>
                                  <div className="mt-2 mt-md-0 ms-lg-1 text-lg-start rltnshp-txt">
                                    <p className="mb-1">{option.name}</p>
                                    {option.subtext && (
                                      <small className="d-block">
                                        {option.subtext}
                                      </small>
                                    )}
                                  </div>
                                </div>
                              </label>
                            </div>
                            <div className="col-1 d-flex align-items-center">
                              <div className="form-check form-checked">
                                <input
                                  name={name}
                                  type="radio"
                                  value={option.id}
                                  className="form-check-input"
                                  id={name + `-radio-` + option.id}
                                  onClick={() => setSelectedValue(option.id)}
                                  checked={selectedValue === option.id}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <NoData />
                    );
                  })()
                ) : (
                  <NoData />
                )}
              </div>

              <div className="row">
                <div className="col-12">
                  <button
                    type="button"
                    className="btn btn-primary rounded-pill w-100 my-2 p-2"
                    onClick={handleConfirm}
                    disabled={!selectedValue}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </ModalBox>
          </div>
        )}
      />

      {errors[name] && <span className="error">{errors[name].message}</span>}
    </div>
  );
}

export default PopupSelect;
