import React from "react";

function NoData() {
  return (
    <div className="col-12 text-center mt-5">
      <p>No records found</p>
    </div>
  );
}

export default NoData;
