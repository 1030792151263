import React, { useState, useEffect } from "react";
import { MakeAxiosRequest } from "../../utils/handler";
import { useDispatch, useSelector } from "react-redux";
import CategoryCard from "../../Components/listing/CategoryCard";
import wallet from "../../Assets/Images/wallet.png";
import relative from "../../Assets/Images/realative.png";
import bgDays from "../../Assets/Images/bg-days.png";
import Loader from "../../Components/loader/Loader";
import ListingPage from "../../Components/listing/ListingPage";
import TableContent from "../../Components/listing/TableContent";
import MembershipPendingModal from "../Membership/MembershipPendingModal";
import { useNavigate, useParams } from "react-router-dom";
import CreateRelationship from "../Relationship/CreateRelationship";
import Button from "../../Components/inputs/Button";
import WalletTopup from "../Wallet/WalletTopup";
import { SwalConfirm } from "../../Components/SwalFns";
import SelectUserModal from "./SelectUserModal";

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = useSelector((state) => state.auth.user);
  const userProxyKey = useSelector((state) => state.auth.proxyKey);
  const userProxyType = useSelector((state) => state.auth.proxyType);

  const [data, setData] = useState("");

  useEffect(() => {
    dashboard();
    fetchData();
  }, []);

  
  useEffect(() => {
    dashboard();
  }, [userProxyKey]);

  const [membeshipModalShow, setMembeshipModalShow] = useState(false);
  const [walletmodalShow, setWalletModalShow] = useState(false);
  const [showSwitchUser, setShowSwitchUser] = useState(false);

  const walletTopup = () => {
    if(data.has_membership =='0'){
      SwalConfirm({
         subText: data.topup_warning_message,
         // actionUrl: "removecard",
         // actionParams: { user_card_uuid: key },
         // successMsg: `Deleted successfully`,
         successFn:  ()=>setMembeshipModalShow(true),
         errorMsg: "An error occurred.",
       });
     // SwalWarning({ msg: data.topup_warning_message });
     // setMembeshipModalShow(true);
 }else{
     setWalletModalShow(true);
 }

}
const closeTopupModal = () => {
  setWalletModalShow(false);
};
const [listParams, setListParams] = useState({
  offset: 0,
  search_term: '',
  limit: 10,
});

  const [response, setResponse] = useState([]);
  const [hasServiceRequests, setHasServiceRequests] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState({
    page: true,
    table: true,
  });
  const columns = [
    {
      header: "Service Name",
      type: "text",
      isShow: true,
      data: {
        text: "category_name",
      },
    },
    {
      header: "Sub Category",
      type: "text",
      isShow: true,
      data: {
        text: "sub_category",
      },
    },
    {
      header: "Repeat Service",
      type: "text",
      isShow: true,
      data: {
        text: "repeat_service",
      },
    },
    {
      header: "Repeat On",
      type: "text",
      isShow: true,
      data: {
        text: "repeat_on",
      },
    },
    {
      header: "Ends At",
      type: "date",
      isShow: true,
      data: {
        text: "ends_at",
      },
    },
    {
      header: "Hours Taken",
      type: "text",
      isShow: true,
      data: {
        text: "hours_taken",
      },
    },
    {
      header: "Hourly Rate",
      type: "text",
      isShow: true,
      data: {
        text: "hourly_rate",
      },
    },
    {
      header: "Actions",
      type: "actionBtns",
      isShow: true,
      buttons: [
        {
          type: "edit",
          key: "service_request_uuid",
          isShow: true,
          function: "",
        },
        {
          type: "delete",
          key: "service_request_uuid",
          isShow: true,
          function: "",
        },
      ],
    },
  ];
  const fetchData = async () => {
    if (response.length === 0)
      setLoadingStatus((prevParams) => ({ ...prevParams, page: true }));

    setLoadingStatus((prevParams) => ({ ...prevParams, table: true }));
    try {
      const res = await MakeAxiosRequest("post", "orderhistory");
      if (res.name !== "CanceledError" && res.status == 1) {
        setResponse(res.data);
        setLoadingStatus({ page: false, table: false });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoadingStatus({ page: false, table: false });
    }
  };
  const dashboard = async () => {
    try {
      const response = await MakeAxiosRequest("post", "/home",{user_proxy_key: userProxyKey,user_proxy_type: userProxyType});
      // console.log(response)
      if (response.status == 1) {
        setData(response.data);
        console.log(response.data.wallet_amount);
        if (response.data.has_service_requests == "1") {
          setHasServiceRequests(true);
        }
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const [modalShow, setModalShow] = useState(false);

  const handleClose = () => {
    setModalShow(false);
  };

  return (
    <>
      {data ? (
        <>
        {data?.show_profile_arrow==1 && (
          <div className="col-12 mb-3" >
            <a href="#" className="order-to" onClick={()=>setShowSwitchUser(true)}>
              <img src={data?.profile_image_path} className="prfl-sec"></img>
              <div>
                {data?.profile_name_label!="Welcome" && <smal className="gray">{data?.profile_name_label}</smal>}
                <p>{data?.profile_name}</p>
              </div>
              <i class="fa-solid fa-chevron-down ms-4"></i>
            </a>
          </div>
        )}

        {data?.show_free_trail_text==1 && (
          <div className="col-12 relation-sty" >
            <div className="web-card DB-card mb-3 d-flex align-items-center" onClick={()=>navigate('/trialinfo')}>
              <div>
                <h4 className="mb-0">{data.free_trail_text}</h4>
              </div>
              <div className="bgDays-stn">
                <span className="bgDays-text mb-0">{data.free_trail_banner}</span>
                <img className="bgDays" src={bgDays} alt="bg-days.png" />
              </div>
            </div>
          </div>
        )}
          <div className="col-12">
            <div className="web-card dash-service pt-4 mb-3 text-center">
              <div className="">
                <div className="row">
                  <div className="col-12">
                    <div className="pb-3">
                      <h1>Hi {authUser?.first_name}, Welcome to MyMaxine</h1>
                      <p>What would you like today?</p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  {data.services?.map((item, index) => (
                    <CategoryCard item={item} key={index} />
                  ))}
                  {/* <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-3">
           <div className="card">
             <img src="images/banner-services/transport.png"/>
             <p className="dark py-2 mb-0">Transportation</p>
           </div>
         </div>
         <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-3">
           <div className="card">
             <img src="images/banner-services/personal.png"/>
             <p className="dark py-2 mb-0">Personal Care</p>
           </div>
         </div>
         <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-3">
           <div className="card">
             <img src="images/banner-services/care.png"/>
             <p className="dark py-2 mb-0">Care Planning</p>
           </div>
         </div>
         <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-3">
           <div className="card">
             <img src="images/banner-services/home.png"/>
             <p className="dark py-2 mb-0">Home Safety</p>
           </div>
         </div>
         <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-3">
           <div className="card">
             <img src="images/banner-services/errands.png"/>
             <p className="dark py-2 mb-0">Errands & Chores</p>
           </div>
         </div>
         <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-3">
           <div className="card">
             <img src="images/banner-services/others.png"/>
             <p className="dark py-2 mb-0">Other Services</p>
           </div>
         </div> */}
                </div>
              </div>
            </div>
          </div>
          {hasServiceRequests ? (
            <>
              <ListingPage
                totalCount={"2"}
                loadingStatus={loadingStatus.page}
                setLoadingStatus={setLoadingStatus}
                listParams={listParams}
                setListParams={setListParams}
                // createBtn={<Button className="btn btn-primary my-2" >Create </Button>}
              >
                <TableContent
                  columns={columns}
                  listingData={response.orderhistory}
                  loadingStatus={loadingStatus.table}
                  type="Relationship"
                />
              </ListingPage>
            </>
          ) : (
            <>
              <div className="col-md-6 mb-3">
                <div className="web-card d-flex h-100 justify-content-center mb-3">
                  <div className="text-center">
                    <img className="card-wallet" src={wallet} alt="wallet" />
                    <div>
                      <h3>Recharge Your wallet</h3>
                      <p>
                        Conveniently add funds to your wallet to ensure <br />
                        seamless transactions for all your services.
                      </p>
                      <a
                      href="javascript:void(0)"
                        className="btn btn-primary rounded-pill px-5"
                       onClick={walletTopup}
                      >
                        Wallet Recharge
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="web-card d-flex h-100 justify-content-center pb-0 mb-3 position-relative overflow-hidden">
                  <div className="text-center">
                    <div className="mt-3">
                      <h3>Add Your Relationships </h3>
                      <p>
                        Add your close relationships whom you want to book services
                        for.
                      </p>
                      <a
                        className="btn btn-primary rounded-pill px-5"
                        onClick={()=>setModalShow(true)}
                      >
                        Add Relationships
                      </a>
                    </div>
                    <img
                      className="card-realtive mt-2"
                      src={relative}
                      alt="wallet"
                    />
                  </div>
                </div>
              </div>
              {modalShow && (
                <CreateRelationship
                  showModal={modalShow}
                  closeModal={handleClose}
                />
              )}
              {walletmodalShow && (
                <WalletTopup
                    showModal={walletmodalShow}
                    closeModal={closeTopupModal}
                />
              )}
              {membeshipModalShow && (
                <MembershipPendingModal
                showModal={membeshipModalShow}
                closeModal={() => {
                    setMembeshipModalShow(false);
                }}
                />
              )}

              {showSwitchUser && (
                <SelectUserModal
                  showModal={showSwitchUser}
                  closeModal={() => {
                    setShowSwitchUser(false);
                  }}
                />
              )}

            </>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default Dashboard;
