import dashboard from "./Assets/Images/dash-icons/dashboard.png";
import categories from "./Assets/Images/dash-icons/categories.png";
import relationship from "./Assets/Images/dash-icons/relationship.png";
import notification from "./Assets/Images/dash-icons/notification.png";
import servicerequest from "./Assets/Images/dash-icons/recurring.png";
import wallet from "./Assets/Images/dash-icons/wallet.png";
import mycards from "./Assets/Images/dash-icons/cards.png";
import membership from "./Assets/Images/dash-icons/membership.png";
import pausemembership from "./Assets/Images/dash-icons/membership-badge.png";
import deleteaccount from "./Assets/Images/dash-icons/delete.png";
import myreceipts from "./Assets/Images/dash-icons/myreceipts.png";

export const baseUrl = process.env.REACT_APP_API_URL;
export const apiKey = process.env.REACT_APP_API_KEY;
export const appSecretKey = process.env.REACT_APP_SECRET_KEY;
// export const phoneRegex = /^(\+?91[\s.-]?)?([06789]\d{9,10})$/;
// export const phoneRegex = /^(1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/;
// export const phoneRegex = /^(1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})( | -)?[2-9]\d{2}-?\d{4}$/; // old
export const phoneRegex = /^(\(\d{3}\)\s?|\d{3})-?\d{3}-?\d{4}$/;
export const emailRegEx = /^(?!.*\.\.)[A-Za-z0-9._%+-]+@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*\.[A-Za-z]{2,}$/;
export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
const user = localStorage.getItem('user');
const data = JSON.parse(user)



export const sidebarData = [
    {
        type:'dashboard',
        title:'Dashboard',
        link:'dashboard',
        icon: dashboard,
        is_show:true
    },
    {
        type:'categories',
        title:'Categories',
        link:'categories',
        icon: categories,
        is_show:true
    },
    {
        type:'relationship',
        title:'Relationships',
        link:'relationship',
        icon: relationship,
        is_show:true
    },
    {
        type:'notification',
        title:'Notifications',
        link:'notifications',
        icon: notification,
        showCount:true,
        is_show:true
    },
    {
        type:'servicerequests',
        title:'Service Requests',
        link:'servicerequests',
        icon: servicerequest,
        is_show:true
    },
    {
        type:'wallet',
        title:'Wallet',
        link:'wallet',
        icon: wallet,
        is_show:true
    },
    {
        type:'mycards',
        title:'My Cards',
        link:'mycards',
        icon: mycards,
        is_show:true
    },
    {
        type:'membership',
        title:'Membership',
        link:'membership',
        icon: membership,
        is_show:true
    },
    {
        type:'myreceipts',
        title:'My Receipts',
        link:'myreceipts',
        icon: myreceipts,
        is_show:true
    },
    {
        type:'subscriptioncancel',
        title:'Pause Membership',
        link:'',
        icon: pausemembership,
        message:"", 
        apiurl:"cancelsubscription",
        is_show:false
    },
    {
        type:'deleteaccount',
        title:'Delete Account',
        link:'',
        icon: deleteaccount,
        message:"", 
        apiurl:"checkdeletepermission",
        is_show:false
    },
    {
        type:'getstarted',
        title:'Get Started',
        link:'getstarted',
        is_show:false
    },
    {
        type:'trialinfo',
        title:'Trial Info',
        link:'trialinfo',
        is_show:false
    },
]

export const months = [
    { id: '01', fullName: 'January', name: 'Jan' },
    { id: '02', fullName: 'February', name: 'Feb' },
    { id: '03', fullName: 'March', name: 'Mar' },
    { id: '04', fullName: 'April', name: 'Apr' },
    { id: '05', fullName: 'May', name: 'May' },
    { id: '06', fullName: 'June', name: 'Jun' },
    { id: '07', fullName: 'July', name: 'Jul' },
    { id: '08', fullName: 'August', name: 'Aug' },
    { id: '09', fullName: 'September', name: 'Sep' },
    { id: '10', fullName: 'October', name: 'Oct' },
    { id: '11', fullName: 'November', name: 'Nov' },
    { id: '12', fullName: 'December', name: 'Dec' }
  ];

const currentYear = new Date().getFullYear();
export const years = Array.from(new Array(10), (val, index) => ({
  id: currentYear + index,
  name: (currentYear + index).toString(),
}));